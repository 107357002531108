import { template as template_0107b46c13e34c6eab66ff325cff8f88 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0107b46c13e34c6eab66ff325cff8f88(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
