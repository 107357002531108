import { template as template_11e27cf941a44ca5976779c0a6fe7432 } from "@ember/template-compiler";
const FKLabel = template_11e27cf941a44ca5976779c0a6fe7432(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
