import { template as template_5b896d954108474d9f51741036de2c70 } from "@ember/template-compiler";
const WelcomeHeader = template_5b896d954108474d9f51741036de2c70(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
